import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";
import Cookies from 'js-cookie'
import modules from "./modules";

const store = createStore({
  getters: {
    testToken(state) {
      return state.Auth.token;
    },
  },
  modules,
  plugins: [
    createMutationsSharer({
      predicate: ["setAuthToken", "setUserData", "registerTimestamp"],
    }),
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
        setItem: (key, value) =>
          Cookies.set(key, value, { secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
});

/* Object.keys(modules).forEach(k => {
  store.registerModule(k, modules[k])
}) */

export default store;
